import { CheckmarkAltIcon } from '@kijiji/icons/src/icons'
import ExternalLinkIcon from '@kijiji/icons/src/icons/ExternalLink'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { HideAtOrLarger, ShowAtOrLarger } from '@/components/shared/breakpoint'
import { VipSectionTitle } from '@/components/vip/shared/vip-section-title/VipSectionTitle'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { ROUTE_LOCALE } from '@/domain/locale'
import {
  CPOExternalLink,
  CPOProgramLogo,
  DetailList,
} from '@/features/certified-pre-owned/components/styled'
import {
  CPO_ALLOWED_PROGRAMS,
  CPO_BULLETS_PER_PARTNER,
  CPO_PROGRAMS_WITH_TYPOS,
  CPO_REMOVED_PROGRAMS,
} from '@/features/certified-pre-owned/constants/programDetails'
import { useLocale } from '@/hooks/useLocale'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { ListItem } from '@/ui/atoms/list-item'
import { sendToLogger } from '@/utils/sendToLogger'

export type CertifiedPreOwnedProps = {
  partnerId: string
  handleOpenR2SForm: () => void
}

export const CertifiedPreOwned: FC<CertifiedPreOwnedProps> = ({ partnerId, handleOpenR2SForm }) => {
  const { spacing } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.CPO)
  const { routeLocale } = useLocale()

  const getRecognizedPartnerId = (value: string): CPO_ALLOWED_PROGRAMS | undefined => {
    /** If the value is part of the main enum, return that value */
    if (Object.values(CPO_ALLOWED_PROGRAMS).includes(value as CPO_ALLOWED_PROGRAMS)) {
      return value as CPO_ALLOWED_PROGRAMS
    }

    /**
     * Add support for partners with typos coming from MoVe or
     * external APIs the BE doesn't have control over. Those will map to their correct programs
     */
    if (CPO_PROGRAMS_WITH_TYPOS[value]) {
      return CPO_PROGRAMS_WITH_TYPOS[value]
    }

    /**
     * If the BE sends us an unrecognized partner ID we should start logging into sentry
     * to send it to the autos team for validation and adjustment of the valid partners
     *
     * The ones intentionally removed from the supported list should not be logged
     */
    if (Object.values(CPO_REMOVED_PROGRAMS).includes(value as CPO_REMOVED_PROGRAMS)) return

    sendToLogger(`Unrecognized Partner Id: ${partnerId}`, {
      fingerprint: ['CertifiedPreOwned'],
    })
  }
  const recognizedPartnerId = getRecognizedPartnerId(partnerId)

  const getLogoPath = (partnerId: CPO_ALLOWED_PROGRAMS) => {
    // Only apply locale-specific logic for GM logo
    if (partnerId === CPO_ALLOWED_PROGRAMS.GM) {
      const langSuffix = routeLocale === ROUTE_LOCALE.fr ? '-fr' : '-en'
      return `/next-assets/images/cpo/${partnerId}${langSuffix}.webp`
    }

    return `/next-assets/images/cpo/${partnerId}.webp`
  }

  // If the partnerId is not recognized, number of bullets is undefined
  const partnerBulletNumbers: number | undefined = recognizedPartnerId
    ? CPO_BULLETS_PER_PARTNER[recognizedPartnerId]
    : undefined

  return (
    <VipSectionContainer data-testid="certified-pre-owned">
      <Flex flexDirection="column" gap={spacing.large}>
        <VipSectionTitle title={t('cpo:title')} />

        {recognizedPartnerId ? (
          <>
            <CPOProgramLogo alt={recognizedPartnerId} src={getLogoPath(recognizedPartnerId)} />

            <DetailList>
              {Array(partnerBulletNumbers)
                .fill(0)
                .map((_, index) => (
                  <ListItem
                    icon={<CheckmarkAltIcon aria-hidden />}
                    iconSize="1.6rem"
                    key={`certified-pre-owned-${recognizedPartnerId}-${index}`}
                    listItemGap={spacing.mini}
                    variant="icon"
                  >
                    {t(`cpo:${recognizedPartnerId}.bullet_point_${index}`)}
                  </ListItem>
                ))}
            </DetailList>

            <BodyText size="xSmall">{t('cpo:legal_disclaimer')}</BodyText>

            <CPOExternalLink
              hasUnderline
              href={t(`cpo:${recognizedPartnerId}.program_url`)}
              rel="noopener noreferrer"
              size="medium"
              target="_blank"
              variant="secondary"
            >
              {t('cpo:partner_external_url.label')}

              <ExternalLinkIcon aria-hidden />
            </CPOExternalLink>
          </>
        ) : (
          <>
            <BodyText size="medium">{t('cpo:unknown_partner.description')}</BodyText>

            <ShowAtOrLarger breakpoint="medium">
              <BodyText>{t('cpo:unknown_partner.button')}</BodyText>
            </ShowAtOrLarger>

            <HideAtOrLarger breakpoint="medium">
              <Button variant="secondary" onClick={handleOpenR2SForm}>
                {t('cpo:unknown_partner.button')}
              </Button>
            </HideAtOrLarger>
          </>
        )}
      </Flex>
    </VipSectionContainer>
  )
}
